const siteMetadata = {
    title: `UX/UI Designer Junior`,
    siteUrl: `http://apollinegaillard-ux.fr`,
    capitalizeTitleOnHome: false,
    logo: `/images/logo_perso.png`,
    icon: `/images/icon.png`,
    titleImage: `/images/photo.jpg`,
    ogImage: `/images/photo.jpg`,
    twoColumnWall: true,
    cookiePolicy: true,
    introTag: `Recherche ⋅ Conception ⋅ Évaluation`,
    description: `Je veux rendre le quotidien accesible et inclusif. Je suis en recherche d'emploi pour janvier 2021.`,
    about:
        "Avec l'UX, je peux améliorer le parcours émotionnel lié au produit. En plus de cela je m'assure que le produit est ergonomique, accessible et inclusif, en impliquant les utilisateurs pour prendre en compte leurs besoins et leurs retours. Grâce à mes études d'informatique et les différents cours de peinture et dessin que j'ai suivi, j'ai un éventail de compétences et de connaissances utiles à l'UX design. Mon parcours en informatique me permet aussi de comprendre les réalités techniques de l'informatique.",
    author: false,
    blogItemsPerPage: 10,
    portfolioItemsPerPage: 10,
    darkmode: false,
    switchTheme: true,
    navLinks: [
        {
            name: "Blog",
            url: "/blog",
        },
        {
            name: "Travail",
            url: "/portfolio",
        },
        {
            name: "Parcours",
            url: "/parcours",
        },
        {
            name: "Illustrations",
            url: "/gallery",
        },
        {
            name: "Contact",
            url: "/contact",
        }
    ],
    footerLinks: [
        /*{
            name: "PRIVACY POLICY",
            url: "/privacy-policy",
        },*/
        {
            name: "Code du site",
            url: "https://github.com/akzhy/gatsby-starter-elemental",
        },
    ],
    social: [
        {
            name: "LinkedIn",
            icon: "/images/LinkedIn.png",
            url: "https://www.linkedin.com/in/sapphaos/"
        },
        {
            name: "Dribbble",
            icon: "/images/Dribbble.png",
            url: "https://dribbble.com/Sapphaos"
        },
        {
            name: "CV",
            icon: "/images/CV.png",
            url: "/images/cv_gaillard_apolline.pdf"
        },
        {
            name: "Portfolio",
            icon: "/images/PortFolio.png",
            url: "/images/portfolio_gaillard_apolline.pdf"
        },

    ],
    contact: {
        // leave empty ('') or false to hide form
        api_url: false,
        description: `Vous souhaitez discuter avec moi?`,
        mail: "mail@apollinegaillard-ux.fr",
        phone: false,
        address: "Toulouse",
    },
    disqus: "elemental-netlify-com",
}

const beforeContactFormSubmit = data => {
    // Code 0 - success
    // Code 1 - Name
    // Code 2 - Email
    // Code 3 - Message
    // Code 4 - Other
    const errors = []

    if (data.name.trim().length < 2) {
        errors.push({
            code: 1,
            message: "Entrez votre nom",
        })
    }

    if (!data.email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
        errors.push({
            code: 2,
            message: "Entrez une adresse mail valide",
        })
    }

    if (data.message.trim().length < 25) {
        errors.push({
            code: 3,
            message: "Entrez un message d'au moins 25 caractères",
        })
    }

    if (errors.length > 0)
        return {
            result: false,
            errors: errors,
        }

    return {
        data: {
            name: data.name,
            email: data.email,
            message: data.message,
        },
        result: true,
    }
}

const contactFormSubmit = async (api, data) => {
    let res: any = await fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })

    res = await res.json()

    if (res.success) {
        return {
            result: true,
        }
    }
    return {
        result: false,
        ...res,
    }
}

const defaults = {
    disqus: null,
    twoColumnWall: true,
    darkmode: false,
    switchTheme: true,
    capitalizeTitleOnHome: true,
    cookiePolicy: false
}

Object.keys(defaults).forEach(item => {
    if (siteMetadata[item] === undefined) {
        siteMetadata[item] = defaults[item]
    }
})

export { siteMetadata, beforeContactFormSubmit, contactFormSubmit }
